import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import InlineHelp from "../../../../../components/inline-help"
import NotationGroup from "../../../../../components/notation-group"
import Note from "../../../../../components/note"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"

import SprachmelodienNotation from "../../../../../content/kurse/reiseerinnerung/02-dichterwerkstatt/sprachmelodien-notation"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      as="form"
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/02-dichterwerkstatt/alliterationen/loesung" />
      }
    >
      <Seo title="Alliterationen erkennen" />
      <Stack>
        <Heading as="h2" level={2}>
          Alliterationen erkennen
        </Heading>
        <Paragraph>
          Das Versmaß ist aber nicht das einzige Element, das zum besonderen
          Sound des Gedichts beiträgt. Hölderlin hat seine Wörter weniger nach
          den Regeln der Grammatik, sondern vielmehr nach musikalischen
          Prinzipien zusammengesetzt. Aus der Kombination von Wörtern mit
          bestimmten Vokalen und Konsonanten ergibt sich so wie in einem Lied
          eine Art Sprachmelodie.
        </Paragraph>
        <TaskSidebar
          main={
            <NotationGroup
              onNotationsUpdated={(notations) => {
                setAnswer({
                  courseId: "reiseerinnerung",
                  chapterId: "02-dichterwerkstatt",
                  taskId: "alliterationen",
                  answer: notations,
                })
              }}
            >
              <SprachmelodienNotation />
            </NotationGroup>
          }
          sidebar={
            <Stack space={6}>
              <Note variant="task">
                Sieh dir nochmal die 1. Strophe an. Findest du auffällige
                Häufungen von Wörtern, die mit dem gleichen Anlaut beginnen?
                Klicke die Anfangsbuchstaben der Wörter an, um sie zu markieren.
                Klicke erneut auf einen Buchstaben, um die Markierung wieder zu
                entfernen.
              </Note>
              <InlineHelp title="Was ist nochmal ein Anlaut?">
                Ein Anlaut ist der erste Laut eines Wortes oder einer Silbe. Das
                Wort Himbeer zum Beispiel beginnt mit dem Anlaut H, das Wort Eis
                den Anlaut Ei.
              </InlineHelp>
            </Stack>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
